import { assetType } from "@/constants/assets.constants";
import { waitForValue } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const getPermissionsAssetResourceByType = (axiosService, params) => {
    console.log("getPermissionsAssetResourceByType", params);
    switch (params?.assetType) {
        case assetType.partition:
            if (!params?.assetId && !params?.accountId) {
                return waitForValue();
            } else {
                return axiosService
                    .get(
                        `/api/projects/${params?.accountId}/partition-assets/${params?.assetId}`,
                    )
                    .then(getData);
            }
        case assetType.integration:
            if (!params?.assetId) {
                return waitForValue();
            } else {
                return axiosService
                    .get(`/api/instances/${params?.assetId}`)
                    .then(getData);
            }

        default:
            return waitForValue();
    }
};
